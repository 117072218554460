import { Typography } from "@mui/material";
import { styled } from "@mui/system";

// components

import React from "react";

import Page from "../Page";

// ----------------------------------------------------------------------

export default function PrivacyPolicy() {

  const ContainerStyle = styled("div")({
    textAlign: "left",
    "& p": {
      color: "#4a4a49",
      fontSize: "18px",
    },
    "& .content--list--title": {
      textDecoration: "underline",
      fontWeight: 600,
    },
    "& .content--list ol li": {
      listStyle: "lower-alpha",
    },
  });

  return (
    <Page title="Dashboard | Oaza-UI">
      <ContainerStyle>
        <Typography
          variant="h4"
          style={{ textAlign: "start", display: "block" }}
        >
          Privacy Policy
        </Typography>

        <p>
          This Privacy Policy describes how we collect, use, process, and
          disclose your information, including personal information, in
          conjunction with your access to and use of our website and mobile
          application, www.oaza.energy (“Website”).
        </p>
        <p>
          If you see an undefined term in this Privacy Policy, it has the same
          definition as in our Terms of Service.
        </p>

        <p>
          When this policy mentions “Company,” “we,” “us,” or “our” it refers to
          the “Oaza Energy Private Limited.”, the company(ies) responsible for
          your information under this Privacy Policy.
        </p>

        <div className="content">
          <ol>
            <li>
              <h3> INFORMATION WE COLLECT </h3>
            </li>
            <div className="content--list">
              <ol>
                <li>
                  <p>
                    <span className="content--list--title">
                      Information You Give to Us.
                    </span>
                    &nbsp;Information that is necessary for the use of the
                    Website and mobile application. We ask for and collect your
                    personal information about you when you use the Website and
                    mobile application. This information is necessary for the
                    adequate performance of the contract between you and us and
                    to allow us to comply with our legal obligations. Without
                    it, we may not be able to provide you with all the requested
                    services.
                  </p>
                </li>
                <li>
                  <p>
                    <span className="content--list--title">
                      Account Information.
                    </span>
                    &nbsp; When you sign up on the mobile application, we require certain information such as your first name, last name, email address, phone number.
                  </p>
                </li>
                <li>
                  <p>
                    <span className="content--list--title">
                    Communications with Company.
                    </span>
                    &nbsp;When you communicate with Company or use the Website to communicate with us, we collect information about your communication and any information you choose to provide.
                  </p>
                </li>
                <li>
                  <p>
                    <span className="content--list--title">
                      Information you choose to give us.
                    </span>
                    &nbsp;You may choose to provide us with additional personal information in order to obtain a better user experience when using the Website and mobile application. This additional information will be processed based on your consent.
                  </p>
                </li>
                <li>
                  <p>
                    <span className="content--list--title">
                      Other Information.
                    </span>
                    &nbsp;You may otherwise choose to provide us information when you fill in a form, conduct a search, respond to surveys, post to community forums, participate in promotions, or use other features on the Website and mobile application(if available and applicable).
                  </p>
                </li>
                <li>
                  <p>
                    <span className="content--list--title">Information We Automatically Collect from Your Use of the Website and the mobile application.</span>
                    &nbsp;When you use the Website, we automatically collect information, including personal information, about the services you use and how you use them. This information is necessary for the adequate performance of the contract between you and us, to enable us to comply with legal obligations and given our legitimate interest in being able to provide and improve the functionalities of the Website and the mobile application.
                  </p>
                </li>
                <li>
                  <p>
                    <span className="content--list--title">Geo-location Information.</span>
                    &nbsp;When you use certain features of the Website and the mobile application, we may collect information about your precise or approximate location as determined through data such as your IP address or mobile device’s GPS to offer you an improved user experience. Most mobile devices allow you to control or disable the use of location services for applications in the device’s settings menu. The Website may also collect this information even when you are not using the mobile application if this connection is enabled through your settings or device permissions.
                  </p>
                </li>
                <li>
                  <p>
                    <span className="content--list--title">Usage Information.</span>
                    &nbsp;We collect information about your interactions with the Website and the mobile application such as the pages or content you view, your searches for a product/service that is provided by Company, and other actions on the Website.
                  </p>
                </li>
                <li>
                  <p>
                    <span className="content--list--title">Log Data and Device Information.</span>
                    &nbsp;We automatically gather log data and device information when you visit and use our Website or mobile application, even if you haven't created an account or logged in. This includes, but isn't limited to, details about how you interact with the Website (such as clicking on links to third-party apps), your IP address, access timestamps, hardware and software specifications, device details, event information, unique identifiers, crash reports, cookie data, and the pages you view or engage with before or after using the Website. Additionally, we collect Firebase Cloud Messaging (FCM) tokens to send push notifications, such as updates, promotions, and alerts. These tokens are shared with Firebase in accordance with their Privacy Policy. We ensure the security of your data through encryption and strict access controls, limiting access to authorized personnel only.
                  </p>
                </li>
                <li>
                  <p>
                    <span className="content--list--title">Cookies and Similar Technologies.</span>
                    &nbsp;We use cookies and other similar technologies. We may also allow our business partners to use these tracking technologies on the Website or engage others to track your behavior on our behalf.
                  </p>
                </li>
                <li>
                  <p>
                    <span className="content--list--title">Information We Collect from Third Parties. </span>
                    &nbsp;Company may collect information, including personal information, which others provide about you when they use the Website or reach the Website through a third-party, or obtain information from other sources and combine that with information we collect through the Website and the mobile application. We do not control, supervise or are responsible for how the third parties providing your information process your personal data, and any information request regarding the disclosure of your personal information to us should be directed to such third parties.
                  </p>
                </li>
                <li>
                  <p>
                    <span className="content--list--title">Other Sources.</span>
                    &nbsp;To the extent permitted by applicable law, we may receive additional information about you, such as demographic data or fraud detection information, from third party service providers and/or partners, and combine it with information we have about you. We may receive information about you and your activities on and off the Website and the mobile application through partnerships, or about your experiences and interactions from our partner ad networks.
                  </p>
                </li>
              </ol>
              <li>
                <h3>HOW WE USE INFORMATION WE COLLECT:</h3>
                <div className="content--list">
                  <ol>
                    <li>
                      <p>
                        We use, store, and process information, including
                        personal information, about you to provide, understand,
                        improve, and develop the Website, create and maintain a
                        trusted and safer environment and comply with our legal
                        obligations and enable you to access and use the Website
                        and the mobile application.
                      </p>
                    </li>
                    <li>
                      <p>
                        We may use the information to operate, protect, improve,
                        and optimize the Website and the mobile application and
                        experience, such as by performing analytics and
                        conducting research.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="content--list--title">
                          Provide customer service.
                        </span>
                        &nbsp;Send you service or support messages, updates,
                        security alerts, and account notifications.
                      </p>
                    </li>
                    <li>
                      <p>
                        If you provide us with your contacts’ information, we
                        may process this information: to facilitate your
                        queries, for fraud detection and prevention and for any
                        purpose you authorize at the time of collection.
                      </p>
                    </li>
                    <li>
                      <p>
                        Create and Maintain a Trusted and Safer Environment.
                      </p>
                    </li>

                    <li>
                      <p>
                        Detect and prevent fraud, spam, abuse, security
                        incidents, and other harmful activity.
                      </p>
                    </li>

                    <li>
                      <p>
                        Conduct security investigations and risk assessments.
                      </p>
                    </li>

                    <li>
                      <p>
                        Verify or authenticate information or identifications
                        provided by you.
                      </p>
                    </li>
                    <li>
                      <p>
                        Conduct checks against databases and other information
                        sources, including background or police checks, to the
                        extent permitted by applicable laws and with your
                        consent where required.
                      </p>
                    </li>

                    <li>
                      <p>Comply with our legal obligations.</p>
                    </li>

                    <li>
                      <p>Enforce our Terms of Service and other policies.</p>
                    </li>

                    <li>
                      <p>
                        We may use the information shared by you to send you
                        promotional messages, marketing, advertising, and other
                        information that may be of interest to you based on your
                        preferences (including information about Company or
                        partner campaigns and services) and social media
                        advertising through social media platforms such as
                        Facebook or Google).
                      </p>
                    </li>
                    <li>
                      <p>
                        We will process your personal information for the
                        purposes listed in this section given our legitimate
                        interest in undertaking marketing activities to offer
                        you products or services that may be of your interest.
                        You can opt-out of receiving marketing communications
                        from us by following the unsubscribe instructions
                        included in our marketing communications.
                      </p>
                    </li>
                  </ol>
                </div>
              </li>
            </div>

            <li>
              <h3> SHARING AND DISCLOSURE: </h3>
            </li>
            <div className="content--list">
              <ol>
                <li>
                  <p>
                    <span className="content--list--title">
                      With Your Consent.
                    </span>
                    &nbsp;Where you have provided consent, we share your
                    information, including personal information, as described at
                    the time of consent, such as when you authorize a third
                    party application or website to access your registered
                    account or when you participate in promotional and/or other
                    activities conducted by Company partners or third parties.
                  </p>
                </li>
                <li>
                  <p>
                    <span className="content--list--title">
                      Compliance with Law, Responding to Legal Requests,
                      Preventing Harm and Protection of our Rights.
                    </span>
                    &nbsp;Company may disclose your information, including
                    personal information, to courts, law enforcement or
                    governmental authorities, or authorized third parties, if
                    and to the extent we are required or permitted to do so by
                    law or if such disclosure is reasonably necessary: (i) to
                    comply with our legal obligations, (ii) to comply with legal
                    process and to respond to claims asserted against Company,
                    (iii) to respond to verified requests relating to a criminal
                    investigation or alleged or suspected illegal activity or
                    any other activity that may expose us, you, or any other of
                    our users to legal liability, (iv) to enforce and administer
                    our Terms of Service, or (v) to protect the rights, property
                    or personal safety of Company, its employees, its
                    affiliates, directors or members of the public at large.
                  </p>
                </li>
                <div className="content">
                  <p>
                    These disclosures may be necessary to comply with our legal
                    obligations, for the protection of your or another person's
                    vital interests or for the purposes of our or a third
                    party’s legitimate interest in keeping the Website and the
                    mobile application secure, preventing harm or crime,
                    enforcing or defending legal rights, or preventing damage.
                  </p>
                </div>
                <div className="content">
                  <p>
                    Where appropriate, we may notify you about legal requests
                    unless: (i) providing notice is prohibited by the legal
                    process itself, by court order we receive, or by applicable
                    law, or (ii) we believe that providing notice would be
                    futile, ineffective, create a risk of injury or bodily harm
                    to an individual or group, or create or increase a risk of
                    fraud upon Company and its Website and/or its Software
                    applications and/or mobile applications. In instances where
                    we comply with legal requests without notice for these
                    reasons, we will attempt to notify that guest about the
                    request after the fact where appropriate and where we
                    determine in good faith that we are no longer prevented from
                    doing so.
                  </p>
                </div>

                <li>
                  <p>
                    <span className="content--list--title">
                      Service Providers.
                    </span>
                    &nbsp;Company uses a variety of third-party service
                    providers to help us provide services related to the Website
                    and the mobile application. These service providers may help
                    us to perform product development, maintenance and
                    debugging, allow the provision of our services through third
                    party platforms and software tools, or provide customer
                    service, advertising, or payments services or any other
                    services. These providers have limited access to your
                    information to perform these tasks on our behalf and are
                    required to protect and to use it only for the purposes for
                    which it was disclosed and consistent with this Privacy
                    Policy.
                  </p>
                </li>
                <li>
                  <p>
                    <span className="content--list--title">
                      Social Media Platforms.
                    </span>
                    &nbsp;Where permissible according to applicable law we may
                    use certain limited personal information about you, such as
                    your email address, to hash it and to share it with social
                    media platforms, such as Facebook or Google, to generate
                    leads, drive traffic to our websites and the mobile
                    application or otherwise promote our products and services
                    or the Website. These processing activities are based on our
                    legitimate interest in undertaking marketing activities to
                    offer you products or services that may be of your interest.
                    The social media platforms with which we may share your
                    personal data are not controlled or supervised by Company.
                    Therefore, any questions regarding how your social media
                    platform service provider processes your personal data
                    should be directed to such provider.
                  </p>
                </li>

                <div className="content">
                  <p>
                    Please note that you may, at any time ask Company to cease
                    processing your data for these direct marketing purposes by
                    sending an e-mail to info@oaza.energy.
                  </p>
                </div>
              </ol>
            </div>
            <li>
              <h3>OTHER IMPORTANT INFORMATION </h3>
            </li>
            <div className="content--list">
              <ol>
                <li>
                  <p>
                    <span className="content--list--title">
                      Analyzing your Communications.
                    </span>
                    &nbsp;We may review, scan, or analyze your communications on
                    the Website for fraud prevention, risk assessment,
                    regulatory compliance, investigation, product development,
                    research, and customer support purposes. In some cases, we
                    may also scan, review, or analyze messages to debug,
                    improve, and expand product offerings. We will not review,
                    scan, or analyze your communications to send third party
                    marketing messages to you, and we will not sell reviews or
                    analyses of these communications.
                  </p>
                </li>
                <li>
                  <p>
                    <span className="content--list--title">
                      Google Maps/Earth
                    </span>
                    &nbsp;Parts of the Website may use Google Maps/Earth
                    services, including the Google Maps API(s). Use of Google
                    Maps/Earth is subject to Google Maps/Earth Additional Terms
                    of Use and the Google Privacy Policy.
                  </p>
                </li>
              </ol>
            </div>
            <li>
              <h3>THIRD PARTY PARTNERS & INTEGRATIONS</h3>
            </li>
            <div className="content--list">
              <ol>
                <li>
                  <p>
                    The Website and the mobile application may contain links to
                    third party websites or services, such as third- party
                    integrations, co-branded services, or third party-branded
                    services (“Third Party Partners”). Company doesn’t own or
                    control these Third-Party Partners and when you interact
                    with them, you may be providing information directly to the
                    Third-Party Partner, Company, or both. These Third-Party
                    Partners will have their own policies for use, protection
                    and disclosure of personal information. We encourage you to
                    review the privacy policies of the other websites you visit.
                  </p>
                </li>
                <li>
                  <p>
                    <span className="content--list--title">Oaza Connect.</span>
                    &nbsp;This “feature”, “service”, “product” referred to by
                    “Oaza Connect” or “it”, is developed in-office at “Oaza
                    Energy Corporate Office, Zeal HR consulting, Sanskriti Blg,
                    Wing H, Flat no.105, Kaspati Wasti, Wakad, Pune, Pune City,
                    Maharashtra, 411057”. It uses third party
                    integrations and telematics to fetch, store and display data
                    from electric vehicles of Oaza Energy users who have
                    consented to the same by signing up for this feature.
                  </p>
                </li>
                <p>
                  All and any data, directly from the user or that is received
                  from third-party aggregators/integrations is managed using all
                  standardised security practices, protocols and measures, that
                  are also explicitly mentioned in the Privacy Policy of the
                  company.
                </p>
                <p>
                  This feature is aimed at providing an enhanced user experience
                  (UX) to all electric vehicle owners who use Oaza’s application
                  and services. The data fetched includes attributes like the
                  vehicle make and model, registration number; Battery details
                  like State of Charger (SoC); Last known location of the
                  vehicle and odometer details like total kms driven, range;
                  etc. The entirety of this data is used to provide value to the
                  user by building personalised dashboards for vehicle
                  management; recommending chargers; charging; battery
                  management etc.
                </p>
                <p>
                  This data is not collected, stored or shared by us with anyone
                  other than the user. It is displayed in real-time as updated
                  and fetched, directly to the user’s dashboards. However, if
                  anyone enrolled for this feature wants to, they can, at any
                  point, withdraw from viewing or accessing it by disconnecting
                  from OazaConnect.
                </p>
                <p>
                  Our aim is to provide the best support, experience, value and
                  product to our users and OazaConnect is another effort towards
                  achieving exactly that.
                </p>
              </ol>
            </div>
            <li>
              <h3>GENERAL DATA PROTECTION REGULATION COMPLIANCES:</h3>
            </li>
            <div className="content--list">
              <ol>
                <li>
                  <p>
                    The General Data Protection Regulation (“GDPR”) (EU)
                    2016/679 is a regulation in EU law on data protection and
                    privacy for all individuals within the European Union. It
                    also addresses the export of personal data outside the EU.
                    The GDPR places limits on the gathering and use of personal
                    data and establishes clear responsibilities for companies
                    that collect it, as well as clear rights for people whose
                    data is gathered. Company is compliant to the GDPR, and if
                    you are an EU resident, then you may access Company’s
                    Privacy Policy applicable to the EU Residents (here).
                  </p>
                </li>
              </ol>
            </div>
            <li>
              <h3>YOUR RIGHTS</h3>
            </li>
            <div className="content--list">
              <ol>
                <li>
                  <p>
                    You are responsible for keeping your personal information
                    up-to-date. You have the right to ask us to correct
                    inaccurate or incomplete personal information concerning
                    you.
                  </p>
                </li>
                <li>
                  <p>
                    In some jurisdictions, applicable law may entitle you to
                    request copies of your personal information held by us. We
                    generally retain your personal information forever in order
                    to comply with our legal obligations. If you no longer want
                    us to use your information to provide the Website to you,
                    you can request that we erase your personal information.
                    Please note that if you request the erasure of your personal
                    information:
                  </p>
                </li>
                <div className="terms-banner--content--list--sub-list">
                  <ol>
                    <li>
                      <p>
                        We may retain some of your personal information as
                        necessary for our legitimate business interests;
                      </p>
                    </li>
                    <li>
                      <p>
                        We may retain and use your personal information to the
                        extent necessary to comply with our legal obligations;
                      </p>
                    </li>
                    <li>
                      <p>
                        Information you have shared with others (e.g., Reviews,
                        forum postings) may continue to be publicly visible.
                      </p>
                    </li>
                  </ol>
                </div>
                <li>
                  <p>
                    <span className="content--list--title">
                      Withdrawing Consent and Restriction of Processing.
                    </span>
                    &nbsp;Where you have provided your consent to the processing
                    of your personal information by Company you may withdraw
                    your consent by sending an e-mail to
                    <b> info@oaza.energy</b>. Please note that the withdrawal
                    of your consent does not affect the lawfulness of any
                    processing activities based on such consent before its
                    withdrawal.
                  </p>
                </li>
                <li>
                  <p>
                    We do not sell your personal information for processing by
                    direct marketing agencies. We may however use your personal
                    information for certain specific purposes (including
                    profiling) where such processing is based on legitimate
                    interest.
                  </p>
                </li>
              </ol>
            </div>
            <li>
              <h3>GENERAL DATA PROTECTION REGULATION COMPLIANCES:</h3>
            </li>
            <div className="content--list">
              <ol>
                <li>
                  <p>
                    The General Data Protection Regulation (“GDPR”) (EU)
                    2016/679 is a regulation in EU law on data protection and
                    privacy for all individuals within the European Union. It
                    also addresses the export of personal data outside the EU.
                    The GDPR places limits on the gathering and use of personal
                    data and establishes clear responsibilities for companies
                    that collect it, as well as clear rights for people whose
                    data is gathered. Company is compliant to the GDPR, and if
                    you are an EU resident, then you may access Company’s
                    Privacy Policy applicable to the EU Residents (here).
                  </p>
                </li>
                <li>
                  <p>
                  We are continuously implementing and updating administrative, technical, and physical security measures to help protect your information against unauthorized access, loss, destruction, or alteration. Some of the safeguards we use to protect your information are firewalls and data encryption, and information access controls. If you know or have reason to believe that your credentials as shared with Company via the Website have been lost, stolen, misappropriated, or otherwise compromised or in case of any actual or suspected unauthorized use of the information shared by you with Company, please contact us.
                  </p>
                </li>
              </ol>
            </div>
            <li>
              <h3>CHANGES TO THIS PRIVACY POLICY</h3>
            </li>
            <div className="content">
              <p>
                Company reserves the right to modify this Privacy Policy at any
                time in accordance with this provision. If we make changes to
                this Privacy Policy, we will post the revised Privacy Policy on
                the Website and the mobile application and update the “Last
                Updated” date at the top of this Privacy Policy. Your continued
                access to or use of the Website will be subject to the revised
                Privacy Policy.
              </p>
            </div>
            <li>
              <h3>CONTACT US</h3>
            </li>
            <div className="content">
              <p>
              If you have any questions or complaints about this Privacy Policy or Company’s information handling practices, you may email us or contact us at:
              </p>
              <p>
                <b>E-mail id: </b>
                <a href="mailto:info@oaza.energy">
                 info@oaza.energy
                </a>
              </p>
              <p>
                <b>Contact Information:</b>
                <br />
                <span>India: <a href="tel:+918071295355"> +91 8071295355</a></span>
              </p>
            </div>
          </ol>
        </div>
      </ContainerStyle>
    </Page>
  );
}
