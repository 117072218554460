import { useState, useRef } from "react";
import { Box, Container, Button, Snackbar, IconButton } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import ReCAPTCHA from "react-google-recaptcha";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import EmailIcon from "@mui/icons-material/Email";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { Link as RouterLink } from "react-router-dom";
import "./OazaFooter.css";
import cmLogoWhite from "../../../image/logo/logo_white150x45.png";
import AppStore from "../../../image/app-store.png";
import GooglePlay from "../../../image/android-download.svg";
import IndiaFlagSvg from "../../../image/india-flag.svg";
import UkFlagSvg from "../../../image/uk-flag.svg";

export const OazaFooterPrimary = () => {
  const [captcha, setCaptcha] = useState(null);
  const captchaRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [snackBarStatus, setSnackbarStatus] = useState(false);
  const [snackBarMessage, setSnackbarMessage] = useState("");

  function onChange(value) {
    if (value) {
      setCaptcha(value);
    }
  }
  const subscribeUser = async (email) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_END_POINT}subscribe`,
        {
          email,
        }
      );
      captchaRef.current.reset();
      setCaptcha(null);
      setIsLoading(false);
      if (response.status === 200) {
        return response?.data;
      }
      return null;
    } catch (error) {
      setIsLoading(false);
      setCaptcha(null);
      captchaRef.current.reset();
      console.error("Error:", error.message);
      return null;
    }
  };

  async function handleSubscribeNewsletter(event) {
    event.preventDefault();
    const formData = new FormData(event.target);
    const email = formData.get("email");
    const resp = await subscribeUser(email);
    setSnackbarStatus(true);
    if (resp?.data?.isAlreadySubscribed) {
      setSnackbarMessage("Already Subscribed");
    } else if (resp && resp?.data.success) {
      setSnackbarMessage("successfully subscribed to our newsletter");
    } else {
      setSnackbarMessage("Something went wrong");
    }
  }

  const handleCloseSnackBar = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarStatus(false);
  };

  const snackBarAction = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnackBar}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <Box className="footerPrimary" padding="60px 0 0">
      <Container>
        <Grid container justifyContent="center" padding={2} color="white">
          <Grid item xs={12} sm={6} md={6} lg={3} mb={{ xs: 4 }}>
            <div className="footerCard">
              <img
                src={cmLogoWhite}
                alt="gym-footer-logo"
                className="pt-footer-logo"
              />
              <p style={{ paddingLeft: "8px" }}>
                Smart solutions for Sustainable Power
              </p>
              {/* <Button style={{ justifyContent: "start" }}>
                <a
                  href="tel:+44-7557906634"
                  style={{
                    color: "white",
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box
                    as="img"
                    sx={{ width: 20, mr: 1 }}
                    src={UkFlagSvg}
                    alt="uk flag"
                  />
                  <span>+44-7557 906634</span>
                </a>
              </Button> */}
              <Button style={{ justifyContent: "start" }}>
                <a
                  href="tel:+918071295355"
                  style={{
                    color: "white",
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box
                    as="img"
                    sx={{ width: 20, mr: 1 }}
                    src={IndiaFlagSvg}
                    alt="india flag"
                  />

                  <span>+91-8071295355</span>
                </a>
              </Button>
              <Button
                startIcon={
                  <EmailIcon
                    className="footer-email-icon"
                    sx={{ fill: "white" }}
                  />
                }
                style={{ justifyContent: "start" }}
              >
                <a
                  href="mailto:info@oaza.energy"
                  style={{
                    color: "white",
                    textDecoration: "none",
                  }}
                >
                  info@oaza.energy
                </a>
              </Button>
              <Box className="cm-apps">
                <a
                  href={process.env.REACT_APP_IOS_APP_STORE_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="sr-only">Get it on App Store</span>
                  <picture>
                    <source media="" type="image/svg" />
                    <img
                      className="image"
                      src={AppStore}
                      alt="Get it on App Store"
                      loading="lazy"
                    />
                  </picture>
                </a>
              </Box>
              <Box className="cm-apps">
                <a href={process.env.REACT_APP_GOOGLE_PLAY_STORE_URL} target="_blank" rel="noopener noreferrer">
                  <span className="sr-only">Get it on Google Play</span>
                  <picture>
                    <source media="" type="image/svg" />
                    <img className="image" src={GooglePlay} alt="Get it on Google Play" loading="lazy" />
                  </picture>
                </a>
              </Box>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} mb={{ xs: 4 }}>
            <div className="footerCard">
              <h1 className="footer-title">Our Services</h1>
              <Button href="/contact-us" startIcon={<ArrowForwardIosIcon />}>
                Partner with us
              </Button>
              <Button href="/contact-us" startIcon={<ArrowForwardIosIcon />}>
                Host a Charging point
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} mb={{ xs: 4 }}>
            <div className="footerCard">
              <h1 className="footer-title">Useful Link</h1>
              <Button
                to="/privacy-policy"
                startIcon={<ArrowForwardIosIcon />}
                component={RouterLink}
              >
                Privacy Policy
              </Button>
              <Button
                to="/refund"
                startIcon={<ArrowForwardIosIcon />}
                component={RouterLink}
              >
                Refund and Cancellation Policy
              </Button>
              <Button
                to="/terms-of-services"
                startIcon={<ArrowForwardIosIcon />}
                component={RouterLink}
              >
                Terms of services
              </Button>
              <Button href="/faqs" startIcon={<ArrowForwardIosIcon />}>
                FAQ's
              </Button>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={3}
            mb={{ xs: 4 }}
            className="subscribe"
          >
            <div className="footerCard">
              <h1 className="footer-title">Subscribe</h1>
              <p>
                Helpful Articles, Network Info, App Developments... Don't Miss
                Out On The Latest News!
              </p>
              <form
                onSubmit={handleSubscribeNewsletter}
                style={{ paddingBottom: "25px" }}
              >
                <input
                  type="email"
                  placeholder="Your email address"
                  name="email"
                  required
                />
                <Box as="div" sx={{ mt: 9 }}>
                  {process.env.REACT_APP_GOOGLE_RECAPTCH_SITE_KEY && (
                    <ReCAPTCHA
                      ref={captchaRef}
                      sitekey={
                        process.env.REACT_APP_GOOGLE_RECAPTCH_SITE_KEY ?? ""
                      }
                      onChange={onChange}
                      size="normal"
                    />
                  )}
                </Box>
                <input
                  style={{
                    cursor: `${captcha !== null ? "pointer" : "not-allowed"}`,
                  }}
                  disabled={captcha !== null && !isLoading ? false : true}
                  type="submit"
                  value="Subscribe"
                ></input>
              </form>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        bgcolor={"#202020"}
        padding="15px"
        color="white"
      >
        <Grid item xs={12}>
          <span className="MuiTypography-caption">
            {" "}
            © 2024 Oaza Energy Private Limited All Rights Reserved.
          </span>
          <div className="MuiTypography-caption uppercase">
            Oaza Energy Private Limited A19, Sai Jyot Park, Nakhate wasti,
            Rahatani-Pimple Saudagar Road, Pune 411017.
          </div>
          <div className="MuiTypography-caption">
            For any grievances related to our service please reach out to{" "}
            <a style={{ color: "lightgray" }} href="mailto:support@oaza.energy">
              support@oaza.energy
            </a>
          </div>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={handleCloseSnackBar}
        open={snackBarStatus}
        autoHideDuration={6000}
        message={snackBarMessage}
        action={snackBarAction}
      />
    </Box>
  );
};
